import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { IconButton, Menu, MenuItem } from "@mui/material";
import CommentIcon from '@mui/icons-material/Comment';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import IosShareIcon from '@mui/icons-material/IosShare';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Rating from '@mui/material/Rating';
import { Card } from "../ui/shadcn/Card";
import { formatUTCDate } from "../utils/formatters";
import { Button } from "./shadcn/Button";
import useStore from "../../store/store";

const DEFAULT_PFP = "/images/Default-PFP.png";
const DEFAULT_ANCHOR_EL_LIKE_MENU = null;
const DEFAULT_IS_LOADING = false;

const ToReads = ({ toReads, refreshToReads, handleToReadClick, page }) => {
    const navigate = useNavigate();
    const [localToReads, setLocalToReads] = useState(toReads);
    const [anchorElLikeMenu, setAnchorElLikeMenu] = useState(DEFAULT_ANCHOR_EL_LIKE_MENU);
    const [likes, setLikes] = useState([]);
    const [isLoading, setIsLoading] = useState(DEFAULT_IS_LOADING);
    const user = useStore((state) => state.user);
    const [followingMap, setFollowingMap] = useState({});
    

    const handleToggleLikeToRead = async (event, toRead_id, profile_id) => {
        event.stopPropagation();
        await setLocalToReads(prevToReads =>
            prevToReads.map(toRead =>
              toRead._id === toRead_id
                ? {
                     ...toRead,
                    liked_by_user: !toRead.liked_by_user,
                    likes: toRead.liked_by_user ? toRead.likes - 1 : toRead.likes + 1
                }
                : toRead
            )            
        );
        try {
            const { data, status } = await axios.post(`/api/e/v1/social/toggle_like_to_read`, {
                toRead_id
            });
            if (status === 200) {
                const message = data?.message;
                if (message === "liked") {
                    handleLikedToReadNotification(toRead_id, profile_id);
                }
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error liking ToRead");
        } finally {
            refreshToReads();
        }
    };

    const handleCloseLikeMenu = () => {
        setAnchorElLikeMenu(null);
    };

    const handleLikedToReadNotification = async (toRead_id, profile_id) => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/new_like_sms", {
                post_id: toRead_id,
                post_type: "to read",
                // The profile is the person who posted the ToRead (they recieve the notification that the user commented on it)
                profile_id: profile_id
            });
        } catch (err) {
            console.error(err);
        }
    }

    const handleShare = async (event, toRead_id) => {
        event.stopPropagation();
        try {
            const url = `${window.location.origin}/toread/${toRead_id}`;
            await navigator.clipboard.writeText(url);
            toast.success("Copied link! Share to socials coming soon. Take a screenshot for now!");
        } catch (err) {
            console.error('Failed to copy: ', err);
            toast.error("Failed to copy the link. Please try again.");
        }
    }

    const handleClickLikeMenu = async (event, toRead_id) => {
        setAnchorElLikeMenu(event.currentTarget);
        setIsLoading(true); // Set loading to true when fetching data
        try {
            // Make a GET request to fetch the likes for the specific post
            const { data, status } = await axios.get("/api/e/v1/social/get_likes", {
                params: {
                    post_id: toRead_id,
                    post_type: "toRead"
                }
            });
            if (status === 200) {
                const likes = data?.likes;
                setLikes(likes);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching likes for the post");
            return []; // Return an empty array in case of an error
        }finally {
            setIsLoading(false); // Set loading to false after data is fetched
        }
    };

    const checkIfFollowing = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/check_if_following", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const isFollowing = data?.is_following;
                setFollowingMap(prev => ({
                    ...prev,
                    [profileId]: isFollowing
                }));
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (user && toReads) {
            toReads.forEach(toRead => {
                if (toRead?.user && toRead.user?._id && user?.id && toRead.user._id !== user.id) {
                    checkIfFollowing(toRead.user._id);
                }
            });
        }
    }, [toReads, user]);

    const handleFollowClick = async (event, profileId) => {
        event.stopPropagation();
        try {
            const { data, status } = await axios.post("/api/e/v1/social/toggle_follow_profile", {
                profile_id: profileId
            });
            if (status === 200) {
                const message = data?.message;
                if (message === "follow") {
                    toast.success("Following user");
                } else if (message === "unfollow") {
                    toast.success("Unfollowing user");
                }
                checkIfFollowing(profileId);
            }
        } catch (err) {
            console.error(err);
            toast.error("Error following user");
        }
    };



    return (
        <>
        <div className={page === 'profile' ? "grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4" : ""}>
            {localToReads && localToReads.sort((a, b) => new Date(b?.published_date) - new Date(a?.published_date)).map((toRead, i) => (
                <div className="pb-3">
                {page === 'profile' ? (
                    <Card key={i} className="w-full overflow-hidden cursor-pointer" onClick={() => handleToReadClick(toRead._id)}>
                        <div className="flex justify-center">
                            <img className="object-cover h-auto w-full max-w-full sm:max-w-xs" src={toRead?.book?.image} alt={toRead?.book?.title} />
                         </div>
                    </Card>
                ) : (      
                <Card key={i} className="w-full overflow-hidden cursor-pointer" onClick={() => handleToReadClick(toRead._id)}>
                    <div className="flex flex-col items-center gap-4 p-4 bg-white rounded-lg shadow">
                        <div className="flex items-center w-full">
                            <div className="flex-row-left-center gap-2 full">
                                <div className="flex-shrink-0">
                                    <img className="w-16 h-16 rounded-full object-cover" src={toRead?.user?.pfp ? toRead?.user?.pfp : DEFAULT_PFP} alt={toRead?.user?.username} onClick={() => navigate(`/profile/${toRead?.user?.username}`)} />
                                </div>
                                <div className="text-left">
                                    <h1 className="text-xl">{toRead?.user?.username} wants to read <i>{toRead?.book?.title.length > 25 ? `${toRead?.book?.title.substring(0, 25)}...` : toRead?.book?.title}</i></h1>
                                    <span className="text-xs text-gray-500">{formatUTCDate(toRead?.published_date)}</span>
                                </div>
                                {user?.id && 
                                toRead?.user?._id && 
                                toRead.user._id !== user.id && 
                                !followingMap[toRead.user._id] && (
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        onClick={(e) => handleFollowClick(e, toRead.user._id)}
                                        className="ml-auto"
                                    >
                                        Follow
                                    </Button>
                                )}
                            </div>
                        </div>
                        <img className="object-cover h-auto w-3/4 max-w-full sm:max-w-xs" src={toRead?.book?.image} alt={toRead?.book?.title} />
                        <div className="flex-col-left-center full mt-4s">
                            <p className="text-sm text-gray-600 text-left overflow-wrap break-words">{toRead?.content}</p>
                        </div>
                        <div className="flex-row-right-center full mt-4">
                            <IconButton>
                                <img src="/images/message-square-02.svg" alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                            </IconButton>
                            <span>{toRead.comments_count}</span>
                            <IconButton onClick={(event) => handleToggleLikeToRead(event, toRead._id, toRead.user._id)}>                                          {(() => {
                                    const hasLiked = toRead?.liked_by_user;
                                    const likeCount = toRead?.likes;
                                    
                                    if (hasLiked && likeCount >= 2) {
                                        return <span className="text-xl">❤️‍🔥</span>;  // Liked with many likes
                                    } else if (hasLiked && likeCount === 1) {
                                        return <span className="textxl">❤️</span>;  // Liked with few likes
                                    } else {
                                        return <span className="text-xl">🤍</span>;  // Unliked with few likes
                                    }
                                })()}
                            </IconButton>
                            <span onClick={(event) => handleClickLikeMenu(event, toRead._id)}>{toRead?.likes}</span>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorElLikeMenu}
                                keepMounted
                                open={Boolean(anchorElLikeMenu)}
                                onClose={handleCloseLikeMenu}
                            >
                                <MenuItem>Liked by:</MenuItem>
                                {likes.map((like, i) => (
                                    <MenuItem key={i}>
                                        <a href={`/profile/${like?.user?.username}`}>
                                            {like?.user?.username ? like?.user?.username : "Unknown User"}
                                        </a>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                        {/* <div className="flex flex-row justify-between items-center full">
                            <div className="flex-row-right-center gap-sm full">
                                <div className="flex-row-right-center gap-sm full">
                                    <IconButton onClick={(event) => handleToggleLikeToRead(event, toRead._id, toRead.user._id)}>
                                        {toRead?.liked_by_user ? <img src="/images/check-heart.svg" alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" /> : <img src="/images/heart.svg" alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />}                                    
                                    </IconButton>
                                    <span className="text-xs text-gray-500">{toRead?.likes_count !== undefined ? toRead.likes_count : toRead?.likes}</span>
                                </div>
                                <div className="flex-row-right-center full">
                                    <IconButton>
                                        <img src="/images/message-square-02.svg" alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                                    </IconButton>
                                    <span className="text-xs text-gray-500">{toRead.comments_count}</span>
                                </div>
                                <div className="flex-row-right-center full">
                                    <IconButton>
                                        <img src="/images/share-03.svg" alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" onClick={(event) => handleShare(event, toRead._id)} />
                                    </IconButton>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Card>
                )}
                </div>
            ))}
        </div>
        </>
    );
};

export default ToReads;