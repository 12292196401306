import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Auth from "./pages/Auth";
import Book from "./pages/Book";
import Meetings from "./pages/Meetings";
import Feed from "./pages/Feed";
import Profile from "./pages/Profile";
import Search from "./pages/Search";
import Settings from "./pages/Settings";
import Following from "./ui/Following"
import useStore from "../store/store";
import * as amplitude from '@amplitude/analytics-browser';

const App = () => {
  const { isUserLoading, user, getUser } = useStore((state) => ({
    isUserLoading: state.isUserLoading,
    user: state.user,
    getUser: state.getUser
  }));
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (!isUserLoading && !user.id && !window.location.pathname.match(/^(\/|\/auth|\/profile\/.*|\/book\/.*)$/)) {
      navigate("/auth");
    }
  }, [isUserLoading, user, navigate]);
  

  useEffect(() => {
    amplitude.init("4b73b03c9933db015b979608583cc4b8");
  }, []);

  return (<>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="light"
      limit={1}
      toastStyle={{ width: "auto", minWidth: "250px" }}
    />
    <Routes>
      <Route path="/auth" element={<Auth />} />
      <Route path="/book/:id" element={<Book />} />
      <Route path="/meetings" element={<Meetings />} />
      <Route path="/feed" element={<Feed />} />
      <Route path="/feed/reviews/:reviewId" element={<Feed />} />
      <Route path="/feed/readings/:readingId" element={<Feed />} />
      <Route path="/feed/toreads/:toReadId" element={<Feed />} />
      <Route path="/profile/:profileUsername" element={<Profile />} />
      <Route path="/profile/:profileUsername/:collectionName" element={<Profile />} />
      <Route path="/profile/:profileUsername/reviews/:reviewId" element={<Profile />} />
      <Route path="/profile/:profileUsername/readings/:readingId" element={<Profile />} />
      <Route path="/profile/:profileUsername/toreads/:toReadId" element={<Profile />} />
      <Route path="/profile/:profileUsername/following" element={<Following />} />
      <Route path="/search" element={<Search />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="*" element={<Navigate to="/auth " replace />} />
    </Routes>
  </>);
};

export default App;