import React, { useEffect, useState, useCallback } from "react";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../ui/shadcn/Button";
import Loader from "../ui/Loader";
import axios from "axios";
import useStore from "../../store/store";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReviewModal from "../ui/ReviewModal";
import ReadingModal from "../ui/ReadingModal";
import ToReadModal from "../ui/ToReadModal";
import debounce from "lodash.debounce"; // Or implement a custom debounce function


const DEFAULT_BOOK_DETAILS = {};
const DEFAULT_BOOK_IMAGE = "/images/Default-PFP.png";
const DEFAULT_IS_BOOK_DETAIL_LOADING = false
const DEFAULT_REVIEW_MODAL_STEP = 2;
const DEFAULT_IS_REVIEW_MODAL_OPEN = false;
const DEFAULT_RATING = 0;
const DEFAULT_CONTENT = "";
const DEFAULT_READ_DATE = null;
const DEFAULT_START_DATE = null;
const DEFAULT_SELECTIONS = [];
const DEFAULT_READING_MODAL_STEP = 2;
const DEFAULT_IS_READING_MODAL_OPEN = false;
const DEFAULT_TO_READ_MODAL_STEP = 2;
const DEFAULT_IS_TO_READ_MODAL_OPEN = false;

const Book = ({}) => {

    const [bookDetails, setBookDetails] = useState(DEFAULT_BOOK_DETAILS);
    const [toReadVariant, setToReadVariant] = useState('outline');
    const [readingVariant, setReadingVariant] = useState('outline');
    const [readVariant, setReadVariant] = useState('outline');
    const [isBookDetailLoading, setIsBookDetailLoading] = useState(DEFAULT_IS_BOOK_DETAIL_LOADING);
    const [isInToRead, setIsInToRead] = useState(false);
    const [isInReading, setIsInReading] = useState(false);
    const [isInRead, setIsInRead] = useState(false);
    const [toReadId, setToReadId] = useState("");
    const [readingId, setReadingId] = useState("");
    const [readId, setReadId] = useState("");
    const [toReadModalStep, setToReadModalStep] = useState(DEFAULT_TO_READ_MODAL_STEP);
    const [reviewModalStep, setReviewModalStep] = useState(DEFAULT_REVIEW_MODAL_STEP);
    const [readingModalStep, setReadingModalStep] = useState(DEFAULT_READING_MODAL_STEP);
    const [isToReadModalOpen, setIsToReadModalOpen] = useState(DEFAULT_IS_TO_READ_MODAL_OPEN);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(DEFAULT_IS_REVIEW_MODAL_OPEN);
    const [isReadingModalOpen, setIsReadingModalOpen] = useState(DEFAULT_IS_REVIEW_MODAL_OPEN);
    const [rating, setRating] = useState(DEFAULT_RATING);
    const [content, setContent] = useState(DEFAULT_CONTENT);
    const [readingContent, setReadingContent] = useState(DEFAULT_CONTENT);
    const [toReadContent, setToReadContent] = useState(DEFAULT_CONTENT);
    const [readDate, setReadDate] = useState(DEFAULT_READ_DATE);
    const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
    const [selections, setSelections] = useState(DEFAULT_SELECTIONS);
    const query = useParams().id;
    const { user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    const navigate = useNavigate();
    const location = useLocation();


    // useEffect(() => {
    //     // First function - runs when the component mounts (page loads)
    //     setIsBookDetailLoading(true);
    //     getBookDetail().then(() => {
    //         // Second function - runs after the first function completes
    //         setIsBookDetailLoading(false);
    //         checkBooksCollections();
    //         setSelections([bookDetails]);
    //     });
    // }, [bookDetails.isbn]); 


    useEffect(() => {
        if (location.state?.isToReadModalOpen) {
            setIsToReadModalOpen(true); // Open modal if `modalOpen` is true when you get redirected back to book after logging in
        }
        else if (location.state?.isReadingModalOpen) {
            setIsReadingModalOpen(true);
        }
        else if (location.state?.isReviewModalOpen) {
            setIsReviewModalOpen(true);
        }
      }, [location.state]);
    
    const fetchBookDetails = async () => {
        setIsBookDetailLoading(true);
        await getBookDetail();
        setSelections([bookDetails]);
        await checkBooksCollections(); // Use debounced function here
        setIsBookDetailLoading(false); 
    };

    useEffect(() => {
        fetchBookDetails();
    }, [bookDetails.isbn]);

    // const debouncedCheckBooksCollections = useCallback(
    //     debounce(() => {
    //         checkBooksCollections();
    //     }, 1000), // 300 ms debounce time, adjust as needed
    //     []
    // );

    useEffect(() => {
        if (!isReviewModalOpen || !isReadingModalOpen || !isToReadModalOpen) {
            checkBooksCollections(); // Use debounced function
        }
    }, [isReviewModalOpen, isReadingModalOpen, isToReadModalOpen]);

    // useEffect(() => {
    //     if (!isReviewModalOpen) {
    //         checkBooksCollections();
    //     }
    // }, [isReviewModalOpen]);

    // useEffect(() => {
    //     if (!isReadingModalOpen) {
    //         checkBooksCollections();
    //     }
    // }, [isReadingModalOpen]);

    // useEffect(() => {
    //     if (!isToReadModalOpen) {
    //         checkBooksCollections();
    //     }
    // }, [isToReadModalOpen]);


    const getBookDetail = async () => {
        try {
            const { data, status } = await axios.get(`/api/e/v1/book/google_books/detail`, {
                params: {
                    query
                }
            });            
            if (status === 200) {
                const book_details = data?.book_details;
                setBookDetails(book_details);
                return book_details;
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching books");
        }
    };

    const checkBooksCollections = async () => {
        console.log("checking books collections");
        try {
            const { data, status } = await axios.get(`/api/e/v1/book/check_books_collection`, {
                params: {
                    isbn: bookDetails?.isbn
                }
            });
            if (status === 200) {
                if (data.isInCollection == true) {
                    if (data.collectionName === 'read') {
                        setReadVariant("default");
                        setIsInRead(true);
                    } else if (data.collectionName === 'reading') {
                        setReadingVariant("default");
                        setIsInReading(true);
                        setReadingId(data.id);
                        setReadingContent(data.reading_content);
                        setToReadContent(data.to_read_content);
                    } else if (data.collectionName === 'toRead') {
                        setToReadVariant("default");
                        setIsInToRead(true);
                        setToReadId(data.id);
                        setToReadContent(data.to_read_content);
                    }       
                }
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error checking books collection");
        }
    };

    const handleToReadToggle = async () => {
        //check to see if there is a user logged in
        if (user.id != undefined) {
            if (isInToRead) {
                toast.info("Already on your TBR");
                return;
            }
            setIsToReadModalOpen(true);
        }
        else {
            navigate("/auth", { state: { from: location.pathname, isToReadModalOpen: true } });
        }
    };

    const handleReadingToggle = async () => {
        if (user.id != undefined) {
            if (isInReading) {
                toast.info("Already reading");
                return;
            }
            if (isInRead) {
                toast.info("Already read");
                return;
            }
            setIsReadingModalOpen(true);
        }
        else {
            navigate("/auth", { state: { from: location.pathname, isReadingModalOpen: true } });
        }
    };

    const handleReadToggle = async () => {
        if (user.id != undefined) {
            if (isInRead) {
                toast.info("Already read");
                return;
            }
            if (isInReading) {
                setIsReviewModalOpen(true);
                // working here rn!! need add some code to delete the "Reading" DB entry!!
                setIsInReading(false);
                setReadingVariant("outline");
                return;
            }
            setIsReviewModalOpen(true);
        }
        else {
            navigate("/auth", { state: { from: location.pathname, isReviewModalOpen: true } });
        }
    };

    const handlePostReview = async () => {
        try {
            await processBook();
            await handleCreateReview();
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        } finally {
            await handleCloseReviewModal();
        }
    };

    const handlePostReading = async () => {
        try {
            await processBook();
            await handleCreateReading();
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        } finally {
            await handleCloseReadingModal();
        }
    };

    const handlePostToRead = async () => {
        try {
            await processBook();
            await handleCreateToRead();
        } catch (err) {
            console.error(err);
            toast.error("Error posting to read");
        } finally {
            await handleCloseToReadModal();
        }
    };

    const handleCloseReviewModal = async () => {
        try {
            setIsReviewModalOpen(DEFAULT_IS_REVIEW_MODAL_OPEN);
            setReviewModalStep(DEFAULT_REVIEW_MODAL_STEP);
            setRating(DEFAULT_RATING);
            setContent(DEFAULT_CONTENT);
            setReadDate(DEFAULT_READ_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing review modal");
        }
    };

    const handleCloseReadingModal = async () => {
        try {
            setIsReadingModalOpen(DEFAULT_IS_READING_MODAL_OPEN);
            setReadingModalStep(DEFAULT_READING_MODAL_STEP);
            setReadingContent(DEFAULT_CONTENT);
            setStartDate(DEFAULT_START_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing reading modal");
        }
    };

    const handleCloseToReadModal = async () => {
        try {
            setIsToReadModalOpen(DEFAULT_IS_TO_READ_MODAL_OPEN);
            setToReadModalStep(DEFAULT_TO_READ_MODAL_STEP);
            setToReadContent(DEFAULT_CONTENT);
        } catch (err) {
            console.error(err);
            toast.error("Error closing to read modal");
        }
    };

    const handleCreateReview = async () => {
        // check to see if it's in an existing collection (based on react states from check books collection)
        if (isInReading == true) {
            // if it is, then create the reading and edit the to be read post (make it hidden)
            try {
                const { data, status } = await axios.post("/api/e/v1/social/create_review", {
                    isbn: bookDetails.isbn,
                    rating: rating,
                    content: content,
                    reading_content: readingContent,
                    to_read_content:toReadContent,
                    read_date: readDate
                });
                if (status == 200) {
                    console.log("200")
                    const { data, status } = await axios.post("/api/e/v1/social/edit_status", {
                        id: readingId,
                        type: "reading",
                        status: "hidden"
                    });
                }
                if (status !== 200) {
                    const err = data?.err;
                    throw new Error({ err, status });
                }
            } catch (err) {
                console.error(err);
                toast.error("Error posting reading");
            }
        }
        else {
            // if it's not, just do a normal create reading 
            try {
                const { data, status } = await axios.post("/api/e/v1/social/create_review", {
                    isbn: bookDetails.isbn,
                    rating: rating,
                    content: content,
                    reading_content: "",
                    to_read_content:"",
                    read_date: readDate
                });
                if (status == 200) {
                    setReadVariant("default");
                }
                if (status !== 200) {
                    const err = data?.err;
                    throw new Error({ err, status });
                }
            } catch (err) {
                console.error(err);
                toast.error("Error posting review");
            }
        }
    };

    const handleCreateReading = async () => {
        // check to see if it's in an existing collection (based on react states from check books collection)
        if (isInToRead == true) {
            // if it is, then create the reading and edit the to be read post (make it hidden)
            try {
                const { data, status } = await axios.post("/api/e/v1/social/create_reading", {
                    isbn: bookDetails.isbn,
                    content: readingContent,
                    to_read_content: toReadContent, //Existing content from want to read post
                    start_date: startDate
                });
                if (status == 200) {
                    console.log("200")
                    const { data, status } = await axios.post("/api/e/v1/social/edit_status", {
                        id: toReadId,
                        type: "toRead",
                        status: "hidden"
                    });
                }
                if (status !== 200) {
                    const err = data?.err;
                    throw new Error({ err, status });
                }
            } catch (err) {
                console.error(err);
                toast.error("Error posting reading");
            }
        }
        else {
            // if it's not, just do a normal create reading 
            try {
                const { data, status } = await axios.post("/api/e/v1/social/create_reading", {
                    isbn: bookDetails.isbn,
                    content: readingContent,
                    to_read_content: "",
                    start_date: startDate
                });
                if (status == 200) {
                    setReadingVariant("default");
                }
                if (status !== 200) {
                    const err = data?.err;
                    throw new Error({ err, status });
                }
            } catch (err) {
                console.error(err);
                toast.error("Error posting reading");
            }
        }
    };

    const handleCreateToRead = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_to_read", {
                isbn: bookDetails.isbn,
                content: toReadContent
            });
            if (status == 200) {
                setToReadVariant("default");
            }
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting to read");
        }
    };

    const processBook = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/book/process_books", {
                selections,
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error processing books");
        }
    };

    return (
        <div className="page flex justify-center items-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Header />
                <div className="flex flex-col sm:flex-row justify-center items-center full">
                    <div className="content text-center pt-4">
                            {isBookDetailLoading ?
                                <Loader /> :
                                <>
                                <div className="gap-2">
                                    <img src={bookDetails?.image ? bookDetails?.image : DEFAULT_BOOK_IMAGE} alt={bookDetails?.title} className="w-48 h-auto md:w-64 md:h-auto mx-auto lg:mx-auto object-cover object-center pb-2" />
                                    <h2 className="text-center pb-2 text-2xl md:text-3xl object-center">{bookDetails?.title}</h2>
                                    <h3 className="text-center pb-2">By: {bookDetails?.authors}</h3>
                                    <div className="flex justify-center gap-2 pb-5">
                                        <Button onClick={handleToReadToggle} variant={toReadVariant}>To Read 📚</Button>
                                        <Button onClick={handleReadingToggle} variant={readingVariant}>Reading 📖</Button>
                                        <Button onClick={handleReadToggle} variant={readVariant}>Read ✅</Button>
                                    </div>
                                    <p className="text-left text-sm md:text-base overflow-wrap break-words">{ ReactHtmlParser(bookDetails?.description) }</p>
                                </div>
                                </>
                           }
                    </div>
                </div>
                <ReviewModal
                    isModalOpen={isReviewModalOpen}
                    setIsModalOpen={setIsReviewModalOpen}
                    modalStep={reviewModalStep} //Skip the searching + selecting 
                    setModalStep={setReviewModalStep}
                    googleBooksSearch={() => {}} // Dummy function
                    setGoogleBooksSearch={() => {}} // Dummy function
                    handleSearchGoogleBooks={() => {}} // Dummy function
                    isDataLoading={() => {}} // Dummy function
                    googleBooksBooks={() => {}} // Dummy function
                    selections={selections} // This might not work! Bu trying to set selection as current book detail
                    handleAddBook={() => {}} // Dummy function
                    handleRemoveBook={() => {}} // Dummy function
                    rating={rating}
                    setRating={setRating}
                    content={content}
                    setContent={setContent}
                    date={readDate}
                    setDate={setReadDate}
                    handlePostReview={handlePostReview}
                    handleCloseReviewModal={handleCloseReviewModal}
                />
                <ReadingModal
                    isModalOpen={isReadingModalOpen}
                    setIsModalOpen={setIsReadingModalOpen}
                    modalStep={readingModalStep} //Skip the searching + selecting 
                    setModalStep={setReadingModalStep}
                    googleBooksSearch={() => {}} // Dummy function
                    setGoogleBooksSearch={() => {}} // Dummy function
                    handleSearchGoogleBooks={() => {}} // Dummy function
                    isDataLoading={() => {}} // Dummy function
                    googleBooksBooks={() => {}} // Dummy function
                    selections={selections} // This might not work! Bu trying to set selection as current book detail
                    handleAddBook={() => {}} // Dummy function
                    handleRemoveBook={() => {}} // Dummy function
                    content={readingContent}
                    setContent={setReadingContent}
                    date={startDate}
                    setDate={setStartDate}
                    handlePostReading={handlePostReading}
                    handleCloseReadingModal={handleCloseReadingModal}
                />
                <ToReadModal
                    isModalOpen={isToReadModalOpen}
                    setIsModalOpen={setIsToReadModalOpen}
                    modalStep={toReadModalStep} //Skip the searching + selecting 
                    setModalStep={setToReadModalStep}
                    googleBooksSearch={() => {}} // Dummy function
                    setGoogleBooksSearch={() => {}} // Dummy function
                    handleSearchGoogleBooks={() => {}} // Dummy function
                    isDataLoading={() => {}} // Dummy function
                    googleBooksBooks={() => {}} // Dummy function
                    selections={selections} // This might not work! Bu trying to set selection as current book detail
                    handleAddBook={() => {}} // Dummy function
                    handleRemoveBook={() => {}} // Dummy function
                    content={toReadContent}
                    setContent={setToReadContent}
                    handlePostToRead={handlePostToRead}
                    handleCloseToReadModal={handleCloseToReadModal}
                />
                <Footer />
            </div>
        </div>
    );
}

export default Book;