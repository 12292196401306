import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import IosShareIcon from '@mui/icons-material/IosShare';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Rating from '@mui/material/Rating';
import { Card } from "./shadcn/Card";
import { Button } from "../ui/shadcn/Button";
import { formatUTCDate } from "../utils/formatters";
import useStore from "../../store/store";


const DEFAULT_PFP = "/images/Default-PFP.png";
const DEFAULT_ANCHOR_EL_LIKE_MENU = null;
const DEFAULT_IS_LOADING = false;

const Readings = ({ readings, refreshReadings, handleReadingClick, page }) => {
    const navigate = useNavigate();
    const [localReadings, setLocalReadings] = useState(readings);
    const [likes, setLikes] = useState([]);
    const [anchorElLikeMenu, setAnchorElLikeMenu] = useState(DEFAULT_ANCHOR_EL_LIKE_MENU);
    const [isLoading, setIsLoading] = useState(DEFAULT_IS_LOADING);
    const user = useStore((state) => state.user); // for following button
    const [followingMap, setFollowingMap] = useState({}); // for following button


    const handleToggleLikeReading = async (event, reading_id, profile_id) => {
        event.stopPropagation();
        await setLocalReadings(prevReadings =>
            prevReadings.map(reading =>
              reading._id === reading_id
                ? {
                     ...reading,
                    liked_by_user: !reading.liked_by_user,
                    likes: reading.liked_by_user ? reading.likes - 1 : reading.likes + 1
                }
                : reading
            )            
        );
        try {
            const { data, status } = await axios.post(`/api/e/v1/social/toggle_like_reading`, {
                reading_id
            });
            if (status === 200) {
                const message = data?.message;
                if (message === "liked") {
                    handleLikedReadingNotification(reading_id, profile_id);
                }
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error liking reading");
        } finally {
            refreshReadings();
        }
    };

    const handleLikedReadingNotification = async (reading_id, profile_id) => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/new_like_sms", {
                post_id: reading_id,
                post_type: "reading",
                profile_id: profile_id
            });
        } catch (err) {
            console.error(err);
        }
    }

    const handleShare = async (event, reading_id) => {
        event.stopPropagation();
        try {
            const url = `${window.location.origin}/reading/${reading_id}`;
            await navigator.clipboard.writeText(url);
            toast.success("Copied link! Share to socials coming soon. Take a screenshot for now!");
        } catch (err) {
            console.error('Failed to copy: ', err);
            toast.error("Failed to copy the link. Please try again.");
        }
    }

    const handleClickLikeMenu = async (event, reading_id) => {
        setAnchorElLikeMenu(event.currentTarget);
        setIsLoading(true); // Set loading to true when fetching data
        try {
            // Make a GET request to fetch the likes for the specific post
            const { data, status } = await axios.get("/api/e/v1/social/get_likes", {
                params: {
                    post_id: reading_id,
                    post_type: "reading"
                }
            });
            if (status === 200) {
                const likes = data?.likes;
                setLikes(likes);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching likes for the post");
            return []; // Return an empty array in case of an error
        }finally {
            setIsLoading(false); // Set loading to false after data is fetched
        }
    };

    const handleCloseLikeMenu = () => {
        setAnchorElLikeMenu(null);
    };

    const checkIfFollowing = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/check_if_following", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const isFollowing = data?.is_following;
                setFollowingMap(prev => ({
                    ...prev,
                    [profileId]: isFollowing
                }));
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
        }
    };

    // Check following status for each reading when component mounts
    useEffect(() => {
        if (user && readings) {
            readings.forEach(reading => {
                if (reading?.user && reading.user?._id && user?.id && reading.user._id !== user.id) {
                    checkIfFollowing(reading.user._id);
                }
            });
        }
    }, [readings, user]);

    // Handle follow button click
    const handleFollowClick = async (event, profileId) => {
        event.stopPropagation();
        try {
            const { data, status } = await axios.post("/api/e/v1/social/toggle_follow_profile", {
                profile_id: profileId
            });
            if (status === 200) {
                const message = data?.message;
                if (message === "follow") {
                    toast.success("Following user");
                } else if (message === "unfollow") {
                    toast.success("Unfollowing user");
                }
                // Refresh the following status for this user
                checkIfFollowing(profileId);
            }
        } catch (err) {
            console.error(err);
            toast.error("Error following user");
        }
    };

    return (
        <>
        <div className={page === 'profile' ? "grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4" : ""}>
            {localReadings && localReadings.sort((a, b) => new Date(b?.published_date) - new Date(a?.published_date)).map((reading, i) => (
                <div className="pb-3">
                    {page === 'profile' ? (
                        <Card key={i} className="w-full overflow-hidden cursor-pointer" onClick={() => handleReadingClick(reading._id)}>
                            <div className="flex justify-center">
                                <img className="object-cover h-auto w-full max-w-full sm:max-w-xs" src={reading?.book?.image} alt={reading?.book?.title} />
                            </div>
                        </Card>
                        ) : (
                        <Card key={i} className="w-full overflow-hidden cursor-pointer" onClick={() => handleReadingClick(reading._id)}>
                                <div className="flex flex-col items-center gap-4 p-4 bg-white rounded-lg shadow">
                                    <div className="flex items-center w-full">
                                        <div className="flex-row-left-center gap-2 full">
                                        <div className="flex-shrink-0">
                                            <img className="w-16 h-16 rounded-full object-cover" src={reading?.user?.pfp ? reading?.user?.pfp : DEFAULT_PFP} alt={reading?.user?.username} onClick={() => navigate(`/profile/${reading?.user?.username}`)} />
                                        </div>
                                            <div className="text-left">
                                                <h1 className="text-xl"><a href={`/profile/${reading?.user?.username}`} className="text-xl">{reading?.user?.username}</a> is reading <i>{reading?.book?.title}</i></h1>
                                                <span className="text-xs text-gray-500">{formatUTCDate(reading?.published_date)}</span>
                                            </div>
                                            {/* Add Follow Button */}
                                            {user?.id && 
                                             reading?.user?._id && 
                                             reading.user._id !== user.id && 
                                             !followingMap[reading.user._id] && (
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={(e) => handleFollowClick(e, reading.user._id)}
                                                    className="ml-auto"
                                                >
                                                    Follow
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <img className="object-cover h-auto w-3/4 max-w-full sm:max-w-xs" src={reading?.book?.image} alt={reading?.book?.title} />
                                    <div className="flex-col-left-center full mt-4s">
                                        <p className="text-sm text-gray-600 text-left overflow-wrap break-words"><u>Reading Notes:</u> {reading?.content}</p>
                                        {reading?.to_read_content?.trim() && (
                                            <p className="text-sm text-gray-600 text-left overflow-wrap break-words">
                                                <u>To read notes:</u> {reading.to_read_content}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex-row-right-center full mt-4">
                                        <IconButton>
                                            <img src="/images/message-square-02.svg" alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                                        </IconButton>
                                        <span>{reading.comments_count}</span>
                                        <IconButton onClick={(event) => handleToggleLikeReading(event, reading._id, reading.user._id)}>                                          {(() => {
                                                const hasLiked = reading?.liked_by_user;
                                                const likeCount = reading?.likes;
                                                
                                                if (hasLiked && likeCount >= 2) {
                                                    return <span className="text-2xl">❤️‍🔥</span>;  // Liked with many likes
                                                } else if (hasLiked && likeCount === 1) {
                                                    return <span className="text-2xl">❤️</span>;  // Liked with few likes
                                                } else {
                                                    return <span className="text-2xl">🤍</span>;  // Unliked with few likes
                                                }
                                            })()}
                                        </IconButton>
                                        <span onClick={(event) => handleClickLikeMenu(event, reading._id)}>{reading?.likes}</span>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorElLikeMenu}
                                            keepMounted
                                            open={Boolean(anchorElLikeMenu)}
                                            onClose={handleCloseLikeMenu}
                                        >
                                            <MenuItem>Liked by:</MenuItem>
                                            {likes.map((like, i) => (
                                                <MenuItem key={i}>
                                                    <a href={`/profile/${like?.user?.username}`}>
                                                        {like?.user?.username ? like?.user?.username : "Unknown User"}
                                                    </a>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                        </Card>
                    )}
                </div>
            ))}
        </div> 
        </>
    );
};

export default Readings;