import React, { useEffect, useState } from "react";
import { Link, useMatch, useNavigate, useResolvedPath, useLocation } from "react-router-dom";
import { InputBase } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import useStore from "../../../store/store";
import { Button } from "../shadcn/Button";
import "../../../styles/index.css"; 
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "../shadcn/Popover"  
import { toast } from "react-toastify";
import axios from "axios";
import ReviewModal from "../ReviewModal";
import ReadingModal from "../ReadingModal";
import ToReadModal from "../ToReadModal";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: 0,
        width: "100%",
    },
    display: "flex",
    alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: "0",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: 0,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

const DEFAULT_SEARCH_QUERY = "";
const DEFAULT_SELECTIONS = [];
const DEFAULT_GOOGLE_BOOKS_SEARCH = "";
const DEFAULT_GOOGLE_BOOKS_BOOKS = [];
const DEFAULT_RATING = 0;
const DEFAULT_CONTENT = "";
const DEFAULT_READING_CONTENT = "";
const DEFAULT_TO_READ_CONTENT = "";
const DEFAULT_READ_DATE = null;
const DEFAULT_MODAL_STEP = 1;
const DEFAULT_IS_MODAL_OPEN = false;
const DEFAULT_IS_DATA_LOADING = false;
const DEFAULT_IS_READING_MODAL_OPEN = false;
const DEFAULT_READING_MODAL_STEP = 1;
const DEFAULT_START_DATE = null;
const DEFAULT_IS_TO_READ_MODAL_OPEN = false;
const DEFAULT_TO_READ_MODAL_STEP = 1;

const Footer = () => {
    const [searchQuery, setSearchQuery] = useState(DEFAULT_SEARCH_QUERY);
    const { user, getUser } = useStore((state) => ({
        user: state.user,
        getUser: state.getUser,
    }));
    const navigate = useNavigate();
    const location = useLocation();
    const [selections, setSelections] = useState(DEFAULT_SELECTIONS);
    const [googleBooksSearch, setGoogleBooksSearch] = useState(DEFAULT_GOOGLE_BOOKS_SEARCH);
    const [googleBooksBooks, setGoogleBooksBooks] = useState(DEFAULT_GOOGLE_BOOKS_BOOKS);
    const [rating, setRating] = useState(DEFAULT_RATING);
    const [content, setContent] = useState(DEFAULT_CONTENT);
    const [readDate, setReadDate] = useState(DEFAULT_READ_DATE);
    const [modalStep, setModalStep] = useState(DEFAULT_MODAL_STEP);
    const [isModalOpen, setIsModalOpen] = useState(DEFAULT_IS_MODAL_OPEN);
    const [isReadingModalOpen, setIsReadingModalOpen] = useState(DEFAULT_IS_READING_MODAL_OPEN);
    const [readingModalStep, setReadingModalStep] = useState(DEFAULT_READING_MODAL_STEP);
    const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
    const [readingContent, setReadingContent] = useState(DEFAULT_CONTENT);
    const [isToReadModalOpen, setIsToReadModalOpen] = useState(DEFAULT_IS_TO_READ_MODAL_OPEN);
    const [toReadModalStep, setToReadModalStep] = useState(DEFAULT_TO_READ_MODAL_STEP);
    const [toReadContent, setToReadContent] = useState(DEFAULT_CONTENT);
    const [isDataLoading, setIsDataLoading] = useState(DEFAULT_IS_DATA_LOADING);


    useEffect(() => {
        getUser();
    }, []);

    const handleSearch = (event) => {
        event.preventDefault();
        navigate(`/search?query=${searchQuery}`);
    };

    const HeaderLink = ({ to, children, startsWith }) => {
        const path = useResolvedPath(to);
        const match = useMatch({ path: path.pathname, end: true });

        const isActive = startsWith
            ? window.location.pathname.startsWith(path.pathname)
            : match;

        const handleClick = (event) => {
            if (!user?.id) {
                event.preventDefault();
                navigate("/auth");
            }
        };

        return (
            <Link to={to} className={isActive ? "header-link-active" : "header-link-inactive"} onClick={handleClick}>
                <small>{children}</small>
            </Link>
        );
    };

    const handleSearchGoogleBooks = async (query) => {
        setIsDataLoading(true);
        try {
            const { data, status } = await axios.get(`/api/e/v1/book/google_books/search`, {
                params: {
                    query
                }
            });
            if (status === 200) {
                const books = data?.books;
                setGoogleBooksBooks(books);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching books");
        } finally {
            setIsDataLoading(DEFAULT_IS_DATA_LOADING);
        }
    };

    const handleAddBook = (book) => {
        setSelections([book]);
        setGoogleBooksBooks([]); // Clear the search results
    };

    const handleRemoveBook = () => {
        setSelections(DEFAULT_SELECTIONS);
    };

    const handlePostReview = async () => {
        try {
            await processBooks();
            await handleCreateReview();
            //I feel like this is such bad form omg
            window.location.reload();
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        } finally {
            await handleCloseModal();
        }
    };

    const handlePostReading = async () => {
        try {
            await processBooksReadOrReading("Reading");
            await handleCreateReading();
            //I feel like this is such bad form omg
            window.location.reload();
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        } finally {
            await handleCloseReadingModal();
        }
    };

    const handlePostToRead = async () => {
        console.log("entered post to read")
        try {
            await processBooksReadOrReading("ToRead");
            await handleCreateToRead();
            //I feel like this is such bad form omg
        } catch (err) {
            console.error(err);
            toast.error("Error posting to read");
        } finally {
            await handleCloseToReadModal();
        }
    };

    const processBooks = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/book/process_books", {
                selections
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        }
    };

    const processBooksReadOrReading = async (collectionName) => {
        try {
            const { data, status } = await axios.post("/api/e/v1/book/process_books", {
                selections,
                collection_name: collectionName
            });
            if (status !== 200) {
                console.log("processBooksReadorReading sends 200")
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error processing books");
        }
    };

    const handleCreateReview = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_review", {
                isbn: selections[0].isbn,
                rating,
                content,
                reading_content:"",
                to_read_content:"",
                read_date: readDate
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        }
    };

    const handleCreateReading = async () => {
        try {
            console.log("here we are")
            const { data, status } = await axios.post("/api/e/v1/social/create_reading", {
                isbn: selections[0].isbn,
                content: readingContent,
                to_read_content:"",
                start_date: startDate
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        }
    };

    const handleCreateToRead = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_to_read", {
                isbn: selections[0].isbn,
                content: toReadContent,
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting to read");
        }
    };

    const handleCloseModal = async () => {
        try {
            setIsModalOpen(DEFAULT_IS_MODAL_OPEN);
            setModalStep(DEFAULT_MODAL_STEP);
            setGoogleBooksSearch(DEFAULT_GOOGLE_BOOKS_SEARCH);
            setGoogleBooksBooks(DEFAULT_GOOGLE_BOOKS_BOOKS);
            setSelections(DEFAULT_SELECTIONS);
            setRating(DEFAULT_RATING);
            setContent(DEFAULT_CONTENT);
            setReadDate(DEFAULT_READ_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing modal");
        }
    };

    const handleCloseReadingModal = async () => {
        try {
            setIsReadingModalOpen(DEFAULT_IS_READING_MODAL_OPEN);
            setReadingModalStep(DEFAULT_READING_MODAL_STEP);
            setReadingContent(DEFAULT_READING_CONTENT);
            setStartDate(DEFAULT_START_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing review modal");
        }
    };

    const handleCloseToReadModal = async () => {
        try {
            setIsToReadModalOpen(DEFAULT_IS_TO_READ_MODAL_OPEN);
            setToReadModalStep(DEFAULT_TO_READ_MODAL_STEP);
            setToReadContent(DEFAULT_TO_READ_CONTENT);
        } catch (err) {
            console.error(err);
            toast.error("Error closing review modal");
        }
    };


    return (
        <div className="footer sticky-footer flex-col-center-center full gap p-2 border-t sm:flex-row-center-center">
            <div className="flex flex-row items-center justify-between w-full">
                <div className="flex-grow flex justify-center">
                    <Link to={`/feed`} className="logo-container"> 
                        <img src={location.pathname === '/feed' ? "/images/home-01-filled.svg" : "/images/home-01.svg"} alt="home icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                    </Link>
                </div>
                <div className="flex-grow flex justify-center">
                    <Link to={`/meetings`} className="logo-container"> 
                        <img src={location.pathname === '/meetings' ? "/images/calendar-filled.svg" : "/images/calendar.svg"} alt="home icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                    </Link>
                </div>
                <div className="flex-grow flex justify-center">
                    <Link to={`/profile/${user.username}`} className="logo-container">
                        <img src={location.pathname === `/profile/${user.username}` ? "/images/user-02-filled.svg" : "/images/user-02.svg"} alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                    </Link>
                </div>
                <div className="flex-grow flex justify-center">
                    <form onSubmit={handleSearch} className="w-3/4">
                        <Search>
                            <img src="/images/search-sm.svg" alt="search icon" className="logo h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                            <StyledInputBase
                                placeholder="Search"
                                inputProps={{ "aria-label": "search" }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full"
                            />
                        </Search>
                    </form>
                </div>
            </div>
            <div className="popover-button">
                <Popover>
                <PopoverTrigger asChild>
                    <Button variant="outline">+ New</Button>
                </PopoverTrigger>
                <PopoverContent className="w-80">
                    <div className="grid gap-4">
                    <Button variant="outline" onClick={() => setIsToReadModalOpen(true)} >To Read 📚</Button>
                    <Button variant="outline" onClick={() => setIsReadingModalOpen(true)} >Reading 📖</Button>
                    <Button variant="outline" onClick={() => setIsModalOpen(true)} >Read ✅</Button>
                    </div>
                </PopoverContent>
                </Popover>
            </div>
            <ReviewModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                modalStep={modalStep}
                setModalStep={setModalStep}
                googleBooksSearch={googleBooksSearch}
                setGoogleBooksSearch={setGoogleBooksSearch}
                handleSearchGoogleBooks={handleSearchGoogleBooks}
                isDataLoading={isDataLoading}
                googleBooksBooks={googleBooksBooks}
                selections={selections}
                handleAddBook={handleAddBook}
                handleRemoveBook={handleRemoveBook}
                rating={rating}
                setRating={setRating}
                content={content}
                setContent={setContent}
                date={readDate}
                setDate={setReadDate}
                handlePostReview={handlePostReview}
                handleCloseReviewModal={handleCloseModal}
            />
            <ReadingModal
                isModalOpen={isReadingModalOpen}
                setIsModalOpen={setIsReadingModalOpen}
                modalStep={readingModalStep} 
                setModalStep={setReadingModalStep}
                googleBooksSearch={googleBooksSearch} 
                setGoogleBooksSearch={setGoogleBooksSearch}
                handleSearchGoogleBooks={handleSearchGoogleBooks}
                isDataLoading={isDataLoading}
                googleBooksBooks={googleBooksBooks}
                selections={selections}
                handleAddBook={handleAddBook}
                handleRemoveBook={handleRemoveBook}
                content={readingContent}
                setContent={setReadingContent}
                date={startDate}
                setDate={setStartDate}
                handlePostReading={handlePostReading}
                handleCloseReadingModal={handleCloseReadingModal}
            />
            <ToReadModal
                isModalOpen={isToReadModalOpen}
                setIsModalOpen={setIsToReadModalOpen}
                modalStep={toReadModalStep} 
                setModalStep={setToReadModalStep}
                googleBooksSearch={googleBooksSearch} 
                setGoogleBooksSearch={setGoogleBooksSearch}
                handleSearchGoogleBooks={handleSearchGoogleBooks}
                isDataLoading={isDataLoading}
                googleBooksBooks={googleBooksBooks}
                selections={selections}
                handleAddBook={handleAddBook}
                handleRemoveBook={handleRemoveBook}
                content={toReadContent}
                setContent={setToReadContent}
                handlePostToRead={handlePostToRead}
                handleCloseToReadModal={handleCloseToReadModal}
            />
        </div>
    );
}

export default Footer;